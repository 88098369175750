import React from 'react'
import { Button, Col, Container, Card, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { textStyle, backgroundStyle } from '../../utils'

export const PeopleCard = ({ heading, subModules, style }) => (
  <Container fluid css={backgroundStyle(style?.backgroundColor)}>
    <Row className="py-4">
      <div className="w-100 mx-auto my-5 text-center">
        <h1 css={textStyle(style?.headingColor)}>{heading}</h1>
      </div>
      <div
        className="people-card-wrapper mx-auto"
        style={{ maxWidth: '1200px' }}
      >
        {subModules &&
          subModules.map(item => (
            <Col key={item.heading} sm={12} md={12} lg={6}>
              <Card
                className="my-3 border-0 people-card"
                style={{ borderRadius: '10px' }}
              >
                <Card.Body className="row">
                  <div className="col-3">
                    {item.imageUrl && <Card.Img src={item.imageUrl}></Card.Img>}
                  </div>
                  <div className="col-7 d-flex flex-column justify-content-center">
                    <Card.Title
                      style={{ fontSize: '1.4rem', textTransform: 'uppercase' }}
                    >
                      <strong>{item.heading}</strong>
                    </Card.Title>
                    <Card.Text>{item.subtitle}</Card.Text>
                  </div>
                  <div className="col-2 d-flex flex-column justify-content-start text-right">
                    {item.button && (
                      <Button variant="primary">{item.button}</Button>
                    )}
                    {item.buttonLink && (
                      <a
                        href={item.buttonLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          style={{ color: '#2067B7' }}
                          className="display-6"
                          icon={faLinkedin}
                        />
                      </a>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </div>
    </Row>
  </Container>
)

export const mapPeopleCardProps = props => {
  props.subModules &&
    props.subModules.forEach(item => {
      if (item.image) {
        item.imageUrl = item.image.file.url
      }
    })

  return props
}
